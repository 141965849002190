import * as Yup from "yup";
import { parse, sub, isDate } from "date-fns";

const POSTCODE_VALIDATION_MSG =
  "Please enter a valid UK Postcode, for example: B69 4DA";
const PASSWORD_VALIDATION_MSG = `A valid password is required.
  The password needs to be between 10 and 64 characters long and must contain at least a number,
  a letter and a symbol`;

const PASSWORD_REGEXP = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
const NAME_REGEXP = /^[A-Za-z\s\-.']+$/;
const PHONE_REGEXP = /^[0-9]{1,20}$/;
const INSURANCE_REGEXP =
  /^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVO][0-9]{6}[ABCD]$/;
const ADDRESS_REGEXP = /^[0-9A-Za-z\s\-.,'/\\()&]+$/;
const POSTCODE_REGEXP = /^(\w{1,2}\d{1,2}\w?\s?\d\w{1,2})$/;

const DATE_FORMAT = "dd/MM/yyyy";

export const applyDetailsValidationSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(10, PASSWORD_VALIDATION_MSG)
      .max(64, PASSWORD_VALIDATION_MSG)
      .matches(PASSWORD_REGEXP, PASSWORD_VALIDATION_MSG)
      .required("Password is required")
      .trim(),

    confirmPassword: Yup.string()
      .required("Password confirmation is required")
      .oneOf([Yup.ref("password"), null], "Does not match password"),

    salutationId: Yup.number().required("Salutation is required"),

    firstName: Yup.string()
      .required("First name is required")
      .trim()
      .max(50, "First name must be maximum 50 characters in length")
      .matches(
        NAME_REGEXP,
        "Invalid first name, please do not use special characters"
      ),

    lastName: Yup.string()
      .required("Last name is required")
      .trim()
      .max(50, "Last name must be maximum 50 characters in length")
      .matches(
        NAME_REGEXP,
        "Invalid last name, please do not use special characters"
      ),

    telephoneNumber: Yup.string()
      .required("Primary telephone number is required")
      .trim()
      .max(20, "Primary telephone number must be 20 characters in length")
      .matches(
        PHONE_REGEXP,
        "Invalid telephone number, please use only digits"
      ),

    nationalInsuranceNumber: Yup.string()
      .required("National insurance number is required")
      .trim()
      .min(9, "Invalid national insurance number")
      .max(9, "Invalid national insurance number")
      .matches(INSURANCE_REGEXP, "Invalid national insurance number"),

    address: Yup.array().of(
      Yup.object().shape({
        street: Yup.string()
          .required("Address line 1 is required")
          .trim()
          .max(35, "Address line 1 must be maximum 35 characters in length")
          .matches(
            ADDRESS_REGEXP,
            "Invalid address line 1, please do not use special characters"
          ),

        suburb: Yup.string()
          .trim()
          .max(35, "Address line 2 must be maximum 35 characters in length")
          .matches(
            ADDRESS_REGEXP,
            "Invalid address line 2, please do not use special characters"
          ),

        city: Yup.string()
          .trim()
          .max(35, "City name must be maximum 35 characters in length")
          .matches(
            ADDRESS_REGEXP,
            "Invalid city name, please do not use special characters"
          ),
        county: Yup.string()
          .trim()
          .max(35, "County name must be maximum 35 characters in length")
          .matches(
            ADDRESS_REGEXP,
            "Invalid county name, please do not use special characters"
          ),
        postcode: Yup.string()
          .trim()
          .required("Post code is required")
          .matches(POSTCODE_REGEXP, POSTCODE_VALIDATION_MSG),
      })
    ),

    privacyPolicy: Yup.bool().oneOf(
      [true],
      "Sorry, You have to read and accept privacy policy"
    ),

    lessThan12m: Yup.bool(),
  });
};
