import React, { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Layout from "../../components/CenteredLayout";
import ToastContent from "../../components/ToastContent";
import Wizard from "./Wizard/Wizard";
import BasicDetails from "./BasicDetails";
import Message from "./Message";
import Journey from "./Journey";
import Apply from "./Apply";
import Details from "./Details";
import Summary from "./Summary";
import Acknowledgement from "./Acknowledgement";
import "./index.scss";
import { isMobile } from "../../utils/userAgent";
import Stepper from "./components/Stepper";
import {
  API_ERROR_CODES,
  API_STEP_KEYS,
  REGISTER_STEPS,
  SESSION_STORAGE_KEYS,
  GENERAL_ERROR_MSG,
} from "../../constants";
import { prospectApi } from "./api";
import { APPLY } from "../../router";

function RegisterRoute() {
  const location = useLocation();
  const history = useHistory();

  const [isDetailsOpen, setIsDetailsOpen] = React.useState(!isMobile || false);
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(REGISTER_STEPS.MESSAGE);
  const [selectedStep, setSelectedStep] = React.useState(REGISTER_STEPS.MESSAGE);

  const onAppStart = useCallback(async () => {
    let launchActiveStep = REGISTER_STEPS.MESSAGE;
    const queryParams = new URLSearchParams(location.search);
    const emailKeyUuid = queryParams.get("emailKeyUuid");
    const prospectId = sessionStorage.getItem(SESSION_STORAGE_KEYS.PROSPECT_ID);

    if (!emailKeyUuid && !prospectId) {
      history.push("/");
    }

    try {
      const { data: userState, error } = await prospectApi.getProspectState({
        emailKeyUuid,
        prospectId,
      });

      if (
        error &&
        [API_ERROR_CODES.EMAIL_NOT_FOUND, API_ERROR_CODES.EMAIL_EXPIRED].includes(
          error[0]?.errorCode
        )
      ) {
        history.push(APPLY);
      }

      if (userState) {
        if (userState.page4Completed) {
          sessionStorage.removeItem(SESSION_STORAGE_KEYS.PROSPECT_ID);
          return (window.location.href = `${process.env.REACT_APP_PORTAL_HOST}login`);
        }

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.PROSPECT_ID,
          userState.prospectId
        );
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.EMAIL,
          userState.emailAddress
        );
        userState.page1Completed && sessionStorage.setItem(
          SESSION_STORAGE_KEYS.MESSAGE_PAGE_COMPLETE,
          true
        );
        userState.page2Completed && sessionStorage.setItem(
          SESSION_STORAGE_KEYS.JOURNEY_PAGE_COMPLETE,
          true
        );

        if (userState.page3Completed) {
          launchActiveStep = REGISTER_STEPS.DETAILS;
        } else if (userState.page2Completed) {
          launchActiveStep = REGISTER_STEPS.APPLY;
        } else if (userState.page1Completed) {
          launchActiveStep = REGISTER_STEPS.JOURNEY;
        }
      }

      setActiveStep(launchActiveStep);
      setSelectedStep(launchActiveStep);

      location.search && history.push({ search: "" }); // clear query params

    } catch (error) {
      toast.error(
        <ToastContent
          title="Error"
          content={GENERAL_ERROR_MSG}
        />
      );
    } finally {
      setIsPageReady(true);
    }
  }, []);

  const hideDetailsMobile = useCallback(() => {
    setIsDetailsOpen(false);
  }, []);

  const showDetailsMobile = useCallback(() => {
    setIsDetailsOpen(true);
  }, []);

  const goToNextStep = useCallback(async () => {
    const updateStepKey = API_STEP_KEYS[activeStep] || null;
    const isDetailsStepPassed = activeStep >= REGISTER_STEPS.DETAILS;

    try {
      if (updateStepKey) {
        const prospectIt = sessionStorage.getItem(
          SESSION_STORAGE_KEYS.PROSPECT_ID
        );

        if (activeStep === selectedStep) {
          const { data: { error } } = await prospectApi.updateProspect(prospectIt, {
            [updateStepKey]: new Date().toISOString(),
          });

          if (error && error[0]) {
            toast.error(
              <ToastContent
                title="Error"
                content={GENERAL_ERROR_MSG}
              />
            );

            return;
          }
        }
      }

      const nextStep = Math.min(
        selectedStep + 1,
        Math.max(...Object.values(REGISTER_STEPS))
      );

      if (activeStep === selectedStep) {
        setActiveStep(nextStep);
      }
      setSelectedStep(nextStep);

      !isDetailsStepPassed && isMobile && hideDetailsMobile();
    } catch (error) {
      // Toast exists on interceptor level
    }
  }, [isMobile, activeStep, selectedStep]);

  const getPrevStep = currentStep => {
    const nextStep = currentStep - 1;

    return nextStep < 0 ? 0 : nextStep;
  };

  const goToPrevStep = useCallback(() => {
    setActiveStep(getPrevStep);
    setSelectedStep(getPrevStep);
  }, [isMobile]);

  useEffect(() => {
    onAppStart();
  }, []);

  return (
    <div className="register">
      <Layout>
        {isPageReady && (
          <>
            <Wizard
              showDetailsMobile={showDetailsMobile}
              activeStep={activeStep}
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
            />
            <BasicDetails isOpen={isDetailsOpen}>
              <Stepper
                activeStep={activeStep}
                selectedStep={selectedStep}
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
                goBack={hideDetailsMobile}
              >
                <Message />
                <Journey />
                <Apply />
                <Details />
                <Summary />
                <Acknowledgement />
              </Stepper>
            </BasicDetails>
          </>
        )}
      </Layout>
    </div>
  );
}

export default RegisterRoute;
