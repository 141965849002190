import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants';

export const TopContent = (props) => {

  return (
    <section className="top-content">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="content-container">
            <h1>We’re here to help</h1>
            <p className='global'>If you’re interested in becoming a DPD Choice Driver and have any questions, we’re happy to help. You can get in touch with our team and they’ll be more than happy to provide you with additional information.</p>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default TopContent;
