import React, { useMemo } from "react";

import "./MyDetails.scss";

const DataBlock = ({ label, text }) => {
  return (
    <div className="data-block">
      <div className="label">{label}</div>
      <div className="text">{text}</div>
    </div>
  );
};

const MyDetails = ({ email, values, salutations, onEdit }) => {
  const salutation = salutations.find(
    salutation => salutation.id === Number(values.salutationId)
  );

  const getAddress = address => {
    if (!address) {
      return null;
    }

    return [
      address.street,
      address.suburb,
      address.city,
      address.county,
    ]
      .filter(v => v)
      .join(", ");
  }

  const fullAddress = useMemo(() => getAddress(values.address[0]), [values.address[0]]);
  const fullOldAddress = useMemo(() => getAddress(values.address[1]), [values.address[1]]);

  return (
    <div className={"card my-details"}>
      <div className="card-header">
        My Details
        {onEdit && (
          <button
            className={"btn btn-outline-danger btn-onboarding"}
            onClick={onEdit}
          >
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          {email && (
            <div className="col-12 col-sm-auto">
              <DataBlock label="Email" text={email} />
            </div>
          )}
          <div className="col col-sm-auto">
            <DataBlock label="Salutation:" text={salutation.description} />
          </div>
          <div className="col col-sm-auto">
            <DataBlock label="First name(s):" text={values.firstName} />
          </div>
          <div className="col col-sm-auto">
            <DataBlock label="Last name:" text={values.lastName} />
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-sm-auto">
            <DataBlock
              label="Primary telephone number"
              text={values.telephoneNumber}
            />
          </div>
          <div className="col-12 col-sm-auto">
            <DataBlock
              label="National insurance number"
              text={values.nationalInsuranceNumber}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DataBlock label="Address" text={fullAddress} />
          </div>
        </div>
        {
          !!values.address[1] && (
            <div className="row">
              <div className="col">
                <DataBlock label="Previous address" text={fullOldAddress} />
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MyDetails;
