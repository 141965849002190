import * as Yup from 'yup';
export const contactUsValidationSchema = () => {
	return Yup.object().shape({
		name: Yup.string()
			.min(8, 'Name must be between 8 and 100 Characters in length.')
			.max(100, 'Name must be between 8 and 100 Characters in length.')
			.required('Please enter the name.'),
		postcode: Yup.string()
			.matches(
				/^(\w{1,2}\d{1,2}\w?\s\d\w{1,2})$/,
				"Please enter a valid UK Postcode, for example: B69 4DA"
			)
			.required('Please enter the postcode.'),
		telephone: Yup.string()
			.required('Please enter the telephone number.'),
		vehicle: Yup.string()
			.required('Please enter the vehicle details.'),
		email_address: Yup.string()
			.matches(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				"Please enter a valid email address."
			).required('Please enter the email address.'),

		valid_uk_licence: Yup.bool().oneOf([true], 'Sorry, You need to have a valid UK driving license.'),
		uk_work_permit: Yup.bool().oneOf([true], 'Sorry, you need to have the right to work in the UK.'),
	})
}
