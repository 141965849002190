import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { find, isEmpty} from "lodash";
import * as Yup from "yup";

import Card from "../components/Card";
import Layout from "../../../components/CenteredLayout";
import emailImg from "../../../assets/images/email.svg";
import * as routes from "../../../router/constants";

import styles from "./EmailSignUp.module.scss";
import classNames from "classnames";
import { SESSION_STORAGE_KEYS, DEPOT_LIST } from "../../../constants";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    )
    .max(50, "Email address cannot be longer than 50 characters")
    .required("Please enter the email address"),
});

const EmailSignUp = ({ update }) => {
  const [depot, setDepot] = useState(""); // set depot entered in the URL
  const history = useHistory();

  const initialEmail = useMemo(() => sessionStorage.getItem("email") || "", []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedValue = urlParams.get("location");
    const decodedValue = decodeURIComponent(encodedValue); // decode url
    const lowercaseUrl = decodedValue.toLowerCase();

    const validDepotList = DEPOT_LIST.map(v => v.toLowerCase()); // convert depot list to lower case

    const validDepot = find(validDepotList, (item) => {return item === lowercaseUrl});

    if(!isEmpty(validDepot)) { // set the depot if it is a valid one
      setDepot(decodedValue);
    } else {
      setDepot("");
    }
  }, []);

  return (
    <Layout>
      <Card title={"Becoming a DPD Driver - Start your journey here"} depot={depot}>
        <div className={styles.emailImage}>
          <img src={emailImg} alt="email" />
        </div>
        <div className={styles.contentWrapper}>
          <div className="card-title">Sign up using your email address</div>
          <div className="card-text">
            To start, please enter your email address. We will use this to send
            you a secure link so you can see a few of the great reasons to drive
            for DPD, and to help you decide if you want to apply. Your future
            could be just a few clicks away
          </div>
          <div className={styles.emailWrapper}>
            <Formik
              initialValues={{ email: initialEmail }}
              validationSchema={ValidationSchema}
              onSubmit={values => {
                sessionStorage.setItem(
                  SESSION_STORAGE_KEYS.EMAIL,
                  values.email
                );
                update();
                history.push(routes.AGREEMENT);
              }}
            >
              {({ isValid, errors, touched, values }) => (
                <Form className="needs-validation">
                  <label
                    htmlFor="email"
                    className={classNames("form-label", styles.formLabel)}
                  >
                    Enter your email address *
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className={classNames("form-control", styles.input)}
                    maxLength="50"
                    placeholder="Email Address *"
                    required
                  />
                  <div className={styles.errorText}>
                    {touched.email && errors.email}
                  </div>
                  <div className="card-footer">
                    <button
                      type="submit"
                      disabled={!isValid || !values.email}
                      className="btn btn-onboarding btn-danger"
                    >
                      Send secure link
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default EmailSignUp;
