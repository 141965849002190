import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Player from "@vimeo/player";

import Card from "../components/Card";
import styles from "./Journey.module.scss";
import { SESSION_STORAGE_KEYS } from "../../../constants";

const Journey = ({ goToNextStep, goBack }) => {
  const [isEnded, setIsEnded] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.JOURNEY_PAGE_COMPLETE)
  );

  let currentTime = 0.1;
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const videoIframe = document.getElementById("video-iframe");
    const newPlayer = new Player(videoIframe);

    newPlayer.on("timeupdate", function (props) {
      newPlayer.getSeeking().then(function (seeking) {
        if (!seeking) {
          currentTime = props.seconds;

          if (props.duration - props.seconds < 10) {
            setIsEnded(true);
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.JOURNEY_PAGE_COMPLETE,
              true
            );
          }
        }
      });
    });

    newPlayer.on("seeking", function (data) {
      var seeking = data.seconds > currentTime;

      if (seeking) {
        newPlayer.setCurrentTime(currentTime);
      }
    });

    setPlayer(newPlayer);
  }, []);

  useEffect(() => {
    if (player && isEnded) {
      player.off("timeupdate");
      player.off("seeking");
    }
  }, [isEnded, player]);

  const readyToApply = () => {
    goToNextStep();
  };

  return (
    <Card
      title={"Owner Driver Journey"}
      subTitle={"Watch our film to see the day in the life of a DPD driver"}
      goBack={() => {
        goBack();
      }}
    >
      <div className={styles.responsiveVideo}>
        <iframe
          src="https://player.vimeo.com/video/856339045?title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Your vehicle, your choice"
          data-ready="true"
          id="video-iframe"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <button
        className={classNames(
          "btn btn-danger btn-onboarding",
          styles.applyButton
        )}
        onClick={readyToApply}
        disabled={!isEnded}
      >
        Ready to apply
      </button>
    </Card>
  );
};

export default Journey;
