import React from "react";

const Stepper = ({ children, activeStep = 0, selectedStep = 0, ...additionalProps }) => {
  const step = selectedStep || activeStep;
  const activeChild = React.Children.toArray(children)[step - 1];

  if (React.isValidElement(activeChild)) {
    return React.cloneElement(activeChild, {
      ...activeChild.props,
      ...additionalProps,
    });
  }

  return null;
};

export default Stepper;
