import React, { useCallback } from "react";
import classNames from "classnames";

import tick from "../../../assets/images/tick.svg";
import play from "../../../assets/images/play-circle.svg";

import styles from "./Wizard.module.scss";
import { REGISTER_STEPS, TAB_STATUS } from "../../../constants";

const Status = ({ status, showDetailsMobile }) => {
  const renderSwitch = param => {
    switch (param) {
      case TAB_STATUS.ACTIVE:
        return (
          <button
            className={classNames(
              "btn btn-onboarding btn-outline-danger",
              styles.editButton
            )}
            onClick={showDetailsMobile}
          >
            Start
          </button>
        );
      case TAB_STATUS.COMPLETED:
        return (
          <div className={styles.status}>
            <div className={styles.statusText}>Completed</div>
            <img className={styles.statusIcon} src={tick} alt="icon" />
          </div>
        );
      default:
        return;
    }
  };

  return <div>{renderSwitch(status)}</div>;
};

const Item = ({ title, icon, status, selected, showDetailsMobile, onClick, selectedStep }) => {
  const navigateToStep = () => {
    if (status && selectedStep !== REGISTER_STEPS.ACKNOWLEDGEMENT) {
      onClick();
      showDetailsMobile();
    }
  };

  return (
    <div
      className={classNames(
        styles.item,
        !status && styles.disabled,
        (status === TAB_STATUS.ACTIVE) && selected && styles.active,
        selected && styles.active,
      )}
    >
      <div className={styles.navigationOverlay} onClick={navigateToStep}></div>
      {icon && <img className={styles.leftIcon} src={icon} alt="icon" />}
      <div className={styles.title}>{title}</div>
      <Status status={status} showDetailsMobile={showDetailsMobile} />
    </div>
  );
};

function Wizard({activeStep, showDetailsMobile, selectedStep, setSelectedStep}) {
  const getTabStatus = useCallback(
    (activeRangeArray, isLastStep) => {
      if (
        activeRangeArray.every(
          step => step < activeStep || (isLastStep && step === activeStep)
        )
      ) {
        return TAB_STATUS.COMPLETED;
      }

      if (activeRangeArray.some(step => step === activeStep)) {
        return TAB_STATUS.ACTIVE;
      }
    },
    [activeStep]
  );

  return (
    <div className={classNames("card", styles.wizard)}>
      <div className={classNames("card-header", styles.header)}>
        Becoming a DPD Driver
      </div>
      <Item
        icon={play}
        title={"Message from DPD"}
        onClick={() => { setSelectedStep(REGISTER_STEPS.MESSAGE) }}
        showDetailsMobile={showDetailsMobile}
        status={getTabStatus([REGISTER_STEPS.MESSAGE])}
        selected={selectedStep === REGISTER_STEPS.MESSAGE}
        selectedStep={selectedStep}
      />
      <Item
        icon={play}
        title={"Owner Driver Journey"}
        onClick={() => { setSelectedStep(REGISTER_STEPS.JOURNEY) }}
        showDetailsMobile={showDetailsMobile}
        status={getTabStatus([REGISTER_STEPS.JOURNEY])}
        selected={selectedStep === REGISTER_STEPS.JOURNEY}
        selectedStep={selectedStep}
      />
      <Item
        title={"Ready to apply?"}
        onClick={() => { setSelectedStep(REGISTER_STEPS.APPLY) }}
        showDetailsMobile={showDetailsMobile}
        status={getTabStatus([REGISTER_STEPS.APPLY])}
        selected={selectedStep === REGISTER_STEPS.APPLY}
        selectedStep={selectedStep}
      />
      <Item
        title={"My details"}
        onClick={() => {
          setSelectedStep(REGISTER_STEPS.DETAILS)
        }}
        showDetailsMobile={showDetailsMobile}
        selected={
          [
            REGISTER_STEPS.DETAILS,
            REGISTER_STEPS.SUMMARY,
            REGISTER_STEPS.ACKNOWLEDGEMENT,
          ].some(step => step === selectedStep)
        }
        selectedStep={selectedStep}
        status={getTabStatus(
          [
            REGISTER_STEPS.DETAILS,
            REGISTER_STEPS.SUMMARY,
            REGISTER_STEPS.ACKNOWLEDGEMENT,
          ],
          true
        )}
      />
    </div>
  );
}

export default Wizard;
