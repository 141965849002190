import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import "./Card.scss";

const Card = ({ title, children, goBack, noHeader, depot }) => {
  return (
    <div className="dpd-card card">
      {
        !noHeader && (
          <div className="card-header">
            {goBack && <div className="back-button" onClick={goBack} />}
            {title}

            {depot && <div className="location">
              <FontAwesomeIcon icon={faLocationDot} />
              {depot}</div>}
          </div>
        )
      }
      <div className="card-body-wrapper">
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default Card;
