import React from "react";
import * as routes from "../../router/constants";

export const FloatingButton = () => {
  return (
    <>
      <div className={`floating-button`}>
        <a href={routes.APPLY}>
          <button className="btn btn-primary">Contact Us</button>
        </a>
      </div>
    </>
  );
};

export default FloatingButton;
