import React from "react";
import { useHistory } from "react-router-dom";

import Card from "../components/Card";
import Layout from "../../../components/CenteredLayout";
import * as routes from "../../../router/constants";
import checkEmail from "../../../assets/images/checkEmail.svg";

import styles from "./CheckYourEmail.module.scss";
import classNames from "classnames";

const CheckYourEmail = () => {
  const history = useHistory();

  const handleClick = () => {
    history.replace(routes.AGREEMENT);
  };

  return (
    <Layout>
      <Card
        title={"Becoming a DPD Driver - Start your journey here"}
        goBack={history.goBack}
      >
        <div className={styles.emailImage}>
          <img src={checkEmail} alt="email" />
        </div>
        <div className={styles.contentWrapper}>
          <div className="card-title">Check your email</div>
          <div className="card-text">
            Success! We’ve sent a secure login link to your email address.
            Please check your inbox and follow the link to continue.
            <br />
            <br />
            If you can’t find the email, please make sure to check your spam
            folder or click the button below to resend the link.
          </div>
        </div>
        <div className="card-footer">
          <button
            className={classNames(
              "btn btn-onboarding btn-link",
              styles.linkButton
            )}
            onClick={handleClick}
          >
            I didn’t receive my email
          </button>
        </div>
      </Card>
    </Layout>
  );
};

export default CheckYourEmail;
