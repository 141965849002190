import React, { useCallback, useEffect, useState } from "react";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import classNames from "classnames";
import { get, set } from "lodash";
import sanitize from "../../../utils/sanitize";

import Card from "../components/Card";

import {
  CustomField,
  CustomPasswordField,
  DatePickerField,
  Autocomplete,
} from "./CustomFields";
import { applyDetailsValidationSchema } from "../../../validation/applyDetailsValidationSchema";
import { SESSION_STORAGE_KEYS } from "../../../constants";
import { referenceApi } from "../api";

import styles from "./Details.module.scss";

const postcodeFormat = (value = "") =>
  value.replace(/\s/g, "").replace(/.{3}$/, " $&");

const PreviousAddress = ({ errors, touched }) => {
  return (
    <>
      <p>Please enter your previous address details below</p>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomField
            id="address[1].street"
            label="Address line 1"
            error={get(errors, "address[1].street")}
            touched={get(touched, "address[1].street")}
            maxLength={35}
          />
        </div>

        <div className="col-lg-6">
          <CustomField
            id="address[1].suburb"
            label="Address line 2 (optional)"
            error={get(errors, "address[1].suburb")}
            touched={get(touched, "address[1].suburb")}
          />
        </div>
      </div>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomField
            id="address[1].city"
            label="City (optional)"
            error={get(errors, "address[1].city")}
            touched={get(touched, "address[1].city")}
            maxLength={35}
          />
        </div>

        <div className="col-lg-6">
          <CustomField
            id="address[1].county"
            label="County (optional)"
            error={get(errors, "address[1].county")}
            touched={get(touched, "address[1].county")}
            maxLength={35}
          />
        </div>
      </div>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <Autocomplete
            id="address[1].postcode"
            name="address[1].postcode"
            label="Post code"
            error={get(errors, "address[1].postcode")}
            touched={get(touched, "address[1].postcode")}
          />
        </div>
      </div>
    </>
  );
};

const FormContent = ({ values, errors, touched, isValid, salutations }) => {
  const { setValues } = useFormikContext();

  const saveValues = useCallback(() => {
    const formatted = sanitize(values);
    const nationalInsuranceNumber = (
      values["nationalInsuranceNumber"] || ""
    ).toUpperCase();
    set(formatted, "nationalInsuranceNumber", nationalInsuranceNumber);

    const formattedPostcode = postcodeFormat(
      get(formatted, "address[0].postcode")
    ).toUpperCase();
    set(formatted, "address[0].postcode", formattedPostcode);

    if (values.lessThan12m) {
      const formattedPostcode = postcodeFormat(
        get(formatted, "address[1].postcode")
      ).toUpperCase();
      set(formatted, "address[1].postcode", formattedPostcode);
    }

    setValues(formatted);
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.DETAILS,
      JSON.stringify(formatted)
    );
  }, [values, setValues]);

  return (
    <Form className={styles.form} onBlur={saveValues}>
      <div className={styles.sectionTitle}>Create your account</div>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomField
            id="email"
            label="Username"
            hint="This email is tied to your secure login link and cannot be changed"
            disabled
          />
        </div>
      </div>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomPasswordField
            id="password"
            label="Enter new password"
            error={errors["password"]}
            touched={touched["password"]}
            minLength={10}
            maxLength={64}
          />
        </div>

        <div className="col-lg-6">
          <CustomPasswordField
            id="confirmPassword"
            label="Confirm new password"
            error={errors["confirmPassword"]}
            touched={touched["confirmPassword"]}
            minLength={10}
            maxLength={64}
          />
        </div>
      </div>

      <div className={styles.sectionTitle}>Provide your details</div>

      {salutations && (
        <div className={classNames("row", styles.row)}>
          <div className="col-lg-4">
            <div className={styles.selectContainer}>
              <Field
                as="select"
                name="salutationId"
                id="salutationId"
                className="form-select"
              >
                <option value=""></option>
                {salutations.map(salutation => (
                  <option key={salutation.id} value={salutation.id}>
                    {salutation.description}
                  </option>
                ))}
              </Field>
              <label
                htmlFor="salutationId"
                id="salutationId-label"
                className={classNames(values.salutationId && styles.selected)}
              >
                Salutation
              </label>
              {errors.salutationId && touched.salutationId ? (
                <div className={styles.error}>{errors.salutationId}</div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomField
            id="firstName"
            label="First name(s)"
            error={errors["firstName"]}
            touched={touched["firstName"]}
            maxLength={24}
          />
        </div>

        <div className="col-lg-6">
          <CustomField
            id="lastName"
            label="Last name"
            error={errors["lastName"]}
            touched={touched["lastName"]}
            maxLength={25}
          />
        </div>
      </div>

      <div className={classNames("row", styles.row)}>
        <div className="col-lg-6">
          <CustomField
              id="nationalInsuranceNumber"
              label="National Insurance Number"
              error={errors["nationalInsuranceNumber"]}
              touched={touched["nationalInsuranceNumber"]}
              minLength={9}
              maxLength={9}
            />
        </div>

        <div className="col-lg-6">
          <CustomField
            id="telephoneNumber"
            label="Primary telephone number"
            error={errors["telephoneNumber"]}
            touched={touched["telephoneNumber"]}
            maxLength={20}
          />
        </div>
      </div>
      <FieldArray
        name="address"
        render={arrayHelpers => {
          return (
            <>
              <div className={classNames("row", styles.row)}>
                <div className="col-lg-6">
                  <CustomField
                    id={`address[0].street`}
                    label="Address line 1"
                    error={get(errors, "address[0].street")}
                    touched={get(touched, "address[0].street")}
                    maxLength={35}
                  />
                </div>

                <div className="col-lg-6">
                  <CustomField
                    id="address[0].suburb"
                    label="Address line 2 (optional)"
                    error={get(errors, "address[0].suburb")}
                    touched={get(touched, "address[0].suburb")}
                    maxLength={35}
                  />
                </div>
              </div>

              <div className={classNames("row", styles.row)}>
                <div className="col-lg-6">
                  <CustomField
                    id="address[0].city"
                    label="City (optional)"
                    error={get(errors, "address[0].city")}
                    touched={get(touched, "address[0].city")}
                    maxLength={35}
                  />
                </div>

                <div className="col-lg-6">
                  <CustomField
                    id="address[0].county"
                    label="County (optional)"
                    error={get(errors, "address[0].county")}
                    touched={get(touched, "address[0].county")}
                    maxLength={35}
                  />
                </div>
              </div>

              <div className={classNames("row", styles.row)}>
                <div className="col-lg-6">
                  <Autocomplete
                    id="address[0].postcode"
                    name="address[0].postcode"
                    label="Post code"
                    error={get(errors, "address[0].postcode")}
                    touched={get(touched, "address[0].postcode")}
                  />
                </div>
              </div>
              <div className={classNames("row", styles.row)}>
                <div
                  className={styles.checkboxFieldWrapper}
                  onClick={e => {
                    if (!e.target.value) return;

                    if (
                      e.target.value === "true" &&
                      values.address.length === 2
                    ) {
                      arrayHelpers.remove(1);
                    } else if (values.address.length === 1) {
                      arrayHelpers.insert(1);
                    }
                  }}
                >
                  <Field name="lessThan12m" id="lessThan12m" type="checkbox" />
                  <label htmlFor="lessThan12m">
                    I have been at this address for less than 12 months
                  </label>
                </div>
              </div>

              {values.lessThan12m ? (
                <PreviousAddress errors={errors} touched={touched} />
              ) : null}
            </>
          );
        }}
      />

      <div className={classNames("row", styles.row)}>
        <div className={styles.checkboxFieldWrapper}>
          <Field name="privacyPolicy" id="privacyPolicy" type="checkbox" />
          <div>
            <label htmlFor="privacyPolicy">
              I have read and understood the{" "}
              <a
                className={styles.link}
                href="https://www.dpd.co.uk/owner-driver-privacy-notice.jsp"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </label>
            {errors["privacyPolicy"] && touched["privacyPolicy"] && (
              <div className={styles.error}>{errors["privacyPolicy"]}</div>
            )}
          </div>
        </div>
      </div>

      <button
        className={"btn btn-danger btn-onboarding confirm-button"}
        type="submit"
        disabled={!isValid || !touched}
      >
        Save
      </button>

      <div className={styles.helperText}>
        Please complete the checklist to confirm
      </div>
    </Form>
  );
};

const Details = ({ goToNextStep, goBack }) => {
  const initialValues = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.DETAILS)
  );
  const email = sessionStorage.getItem(SESSION_STORAGE_KEYS.EMAIL);
  const [salutations, setSalutations] = useState();

  useEffect(() => {
    referenceApi.getSalutations().then(data => {
      setSalutations(data);
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.SALUTATIONS,
        JSON.stringify(data)
      );
    })
    .catch(() => {
      // Toast exists on interceptor level
    });
  }, []);

  return (
    <Card
      title={"My Details"}
      subTitle={"Please enter your details below"}
      goBack={() => {
        goBack();
      }}
    >
      <Formik
        initialValues={
          initialValues || {
            email,
            lessThan12m: false,
            privacyPolicy: false,
            address: [{}],
            salutationId: "",
          }
        }
        validationSchema={applyDetailsValidationSchema}
        validateOnMount={true}
        onSubmit={async () => {
          goToNextStep();
        }}
      >
        {({ values, errors, touched, isValid }) => (
          <FormContent
            salutations={salutations}
            values={values}
            errors={errors}
            touched={touched}
            isValid={isValid}
          />
        )}
      </Formik>
    </Card>
  );
};

export default Details;
