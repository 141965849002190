import axios from "axios";
export const handleRecaptcha = async (action, key) => {
	const token = await window.grecaptcha.enterprise?.execute(key, {
		action: action,
	});

	const response = await axios.post(
		`${process.env.REACT_APP_API_HOST}/captcha`,
		{ token, action: action }
	);
	const score =  response?.data?.score;
	return score > 0.6;
};