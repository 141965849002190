import React from "react";

const XMark = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="xmark-light">
      <path
        id="Vector"
        d="M20.2638 19.7655C19.9512 20.0782 19.4454 20.0782 19.1324 19.7655L12.4999 13.1307L5.8658 19.7655C5.55319 20.0782 5.04741 20.0782 4.73445 19.7655C4.42185 19.4529 4.42185 18.947 4.73445 18.6341L11.37 12.0008L4.73545 5.36595C4.42285 5.05331 4.42285 4.54747 4.73545 4.23448C5.04806 3.92184 5.55384 3.92184 5.8668 4.23448L12.4999 10.8708L19.1339 4.23598C19.4465 3.92334 19.9523 3.92334 20.2653 4.23598C20.5779 4.54862 20.5779 5.05446 20.2653 5.36745L13.6297 12.0008L20.2638 18.6356C20.5787 18.9455 20.5787 19.4555 20.2638 19.7655Z"
        fill="#DC0032"
      />
    </g>
  </svg>
);

export default XMark;
