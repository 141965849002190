import React from 'react';
import ChoiceForm from "../../../../components/ChoiceForm";

export const ChoiceApply = (props) => {

  return (
    <section className="content-apply image-content" id="contactForm">
      <div className="plcholder"></div>
      <div className="container">
        <div className='row'>
          <div className='col-lg-6 content-container'>
            <div className="gutter right">
              <ChoiceForm />
              </div>
            </div>
          <div className="col-lg-6">
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChoiceApply;
