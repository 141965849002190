import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";

import Card from "../components/Card";
import Layout from "../../../components/CenteredLayout";
import ToastContent from "../../../components/ToastContent";
import { prospectApi } from "../../Register/api";
import * as routes from "../../../router/constants";
import Task from "../../../assets/icons/Task";
import Xmark from "../../../assets/icons/XMark";

import styles from "./Agreement.module.scss";
import { SESSION_STORAGE_KEYS, GENERAL_ERROR_MSG } from "../../../constants";

const ListItem = ({ img: ImgComponent, text }) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.listImage}>
        <ImgComponent />
      </div>
      <div className={classNames("card-text", styles.text)}>{text}</div>
    </div>
  );
};

const Agreement = ({ update }) => {
  const history = useHistory();
  const [agree, setAgree] = useState(false);
  const email = useMemo(() => sessionStorage.getItem("email"), []);
  const agreeChecked = useMemo(
    () => sessionStorage.getItem("agreeChecked"),
    []
  );

  const onAgreementChange = () => {
    setAgree(!agree);
  };

  useEffect(() => {
    agreeChecked && setAgree(true);
  }, [agreeChecked]);

  const onConfirm = useCallback(async () => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.AGREEMENT_CHECKED, true);
    update();
    if (email) {
      try {
        const data = await prospectApi.sendEmail(email);

        if (data.error) {
          toast.error(
            <ToastContent
              title="Error"
              content={GENERAL_ERROR_MSG}
            />
          );
        } else {
          history.push(routes.CHECK_YOUR_EMAIL);
        }
      } catch (error) {
        // Toast exists on interceptor level
      }
    }
  }, []);

  const onBackClick = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.AGREEMENT_CHECKED);
    update();
    history.replace(routes.CHECK_YOUR_EMAIL);
  };

  return (
    <Layout>
      <Card
        title={"Becoming a DPD Driver - Start your journey here"}
        goBack={onBackClick}
      >
        <div className={styles.section}>
          <div className={classNames("card-title", styles.title)}>
            This is how DPD will use your email:
          </div>
          <ListItem
            img={Task}
            text={"To keep you informed about future applications"}
          />
          <ListItem
            img={Task}
            text={"Ensure our services continue to meet your needs"}
          />
        </div>
        <div className={styles.section}>
          <div className={classNames("card-title", styles.title)}>
            We will never use your data to:
          </div>
          <ListItem img={Xmark} text={"Sell your data to any third parties"} />
          <ListItem
            img={Xmark}
            text={"Keep your data for longer than what is needed"}
          />
          <ListItem
            img={Xmark}
            text={"Use your data to send you marketing information"}
          />
        </div>
        <div className={styles.section}>
          <div className={classNames("card-text", styles.text)}>
            Please see our{" "}
            <a
              className={styles.link}
              href="https://www.dpd.co.uk/owner-driver-privacy-notice.jsp"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice{" "}
            </a>
            for more details on how your personal data will be used.
          </div>
        </div>
        <div className={classNames([styles.section, styles.agreement])}>
          <input
            type="checkbox"
            id="scales"
            name="agree"
            checked={agree}
            onChange={onAgreementChange}
          />
          <label
            className={classNames("card-text", styles.agreeText)}
            htmlFor="scales"
          >
            I agree to the Terms & Conditions
          </label>
        </div>

        <div className={classNames([styles.section, styles.footer])}>
          <button
            className="btn btn-outline-danger btn-onboarding"
            onClick={onBackClick}
          >
            Back
          </button>
          <button
            className="btn btn-danger btn-onboarding"
            disabled={!agree}
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </Card>
    </Layout>
  );
};

export default Agreement;
