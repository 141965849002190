import React from "react";
import Header from "../OnboardingHeader";

import "./CenteredLayout.scss";

const CenteredLayout = ({ children }) => {
  return (
    <div className="centered-layout-wrapper">
      <Header />
      <main className="centered-layout">{children}</main>
    </div>
  );
};

export default CenteredLayout;
