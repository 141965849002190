import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY } from "constants";
import * as routes from "../../router/constants";

export const VideoSection = props => {
  return (
    <section className="video-section text-center">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeIn"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <h3 className="text-center text-secondary">
            Watch our film to see a day in the life of an Owner Driver
          </h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/l_9eotejSec"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <div className="btn-row">
            <Link to={{ pathname: routes.APPLY }}>
              <button className="btn btn-primary">How To Apply</button>
            </Link>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
};

export default VideoSection;
