import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY, MOBILE_BREAKPOINT } from "constants";
import car1 from "assets/images/car1.png";
import car2 from "assets/images/car2.png";
import car3 from "assets/images/car3.png";

export const Vans = props => {
  const { windowWidth } = props;

  return (
    <section className="vans">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeIn"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <h1>What will I be driving?</h1>
          <p>
            If you have held a valid UK licence for 12 months you can lease from
            our amazing vehicle selection and benefit from the latest electric
            innovations, or use your own van and we will pay for the livery.
          </p>
        </ScrollAnimation>
        <div className="row car-content">
          <div className="col-lg-4 car1">
            <ScrollAnimation
              animateIn="fadeInLeft"
              delay={windowWidth < MOBILE_BREAKPOINT ? 0 : 800}
              initiallyVisible={false}
              animateOnce={true}
            >
              <div className="content">
                <img src={car1} alt="nissan" width={300} />
                nissan
              </div>
            </ScrollAnimation>
          </div>
          <div className="col-lg-4 car2">
            <ScrollAnimation
              animateIn="fadeInLeft"
              delay={windowWidth < MOBILE_BREAKPOINT ? 0 : 600}
              initiallyVisible={false}
              animateOnce={true}
            >
              <div className="content">
                <img src={car2} alt="Maxus" width={300} />
                Maxus
              </div>
            </ScrollAnimation>
          </div>
          <div className="col-lg-4 car3">
            <ScrollAnimation
              animateIn="fadeInLeft"
              delay={windowWidth < MOBILE_BREAKPOINT ? 0 : 400}
              initiallyVisible={false}
              animateOnce={true}
            >
              <div className="content">
                <img src={car3} alt="Ford" width={300} />
                Ford
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vans;
