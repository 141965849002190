import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Player from "@vimeo/player";

import styles from "./Message.module.scss";
import Card from "../components/Card";
import { SESSION_STORAGE_KEYS } from "../../../constants";

const Message = ({ goToNextStep, goBack }) => {
  const [isEnded, setIsEnded] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.MESSAGE_PAGE_COMPLETE)
  );

  let currentTime = 0.1;
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const videoIframe = document.getElementById("video-iframe");
    const newPlayer = new Player(videoIframe);

    newPlayer.on("timeupdate", function (props) {
      newPlayer.getSeeking().then(function (seeking) {
        if (!seeking) {
          currentTime = props.seconds;

          if (props.duration - props.seconds < 10) {
            setIsEnded(true);
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.MESSAGE_PAGE_COMPLETE,
              true
            );
          }
        }
      });
    });

    newPlayer.on("seeking", function (data) {
      var seeking = data.seconds > currentTime;

      if (seeking) {
        newPlayer.setCurrentTime(currentTime);
      }
    });

    setPlayer(newPlayer);
  }, []);

  useEffect(() => {
    if (player && isEnded) {
      player.off("timeupdate");
      player.off("seeking");
    }
  }, [isEnded, player]);

  const applyNow = () => {
    goToNextStep();
  };

  return (
    <Card
      title={"Message from DPD"}
      subTitle={
        "A brief introduction to the culture of DPD and becoming part of the team."
      }
      goBack={() => {
        goBack();
      }}
    >
      <div className={styles.responsiveVideo}>
        <iframe
          src="https://player.vimeo.com/video/856338784?title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Your vehicle, your choice"
          data-ready="true"
          id="video-iframe"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <button
        className={classNames(
          "btn btn-danger btn-onboarding",
          styles.applyButton
        )}
        onClick={applyNow}
        disabled={!isEnded}
      >
        Mark as completed
      </button>
    </Card>
  );
};

export default Message;
