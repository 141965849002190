import React from "react";

import styles from "./Apply.module.scss";
import Card from "../components/Card";
import Task from "../../../assets/icons/Task";
import classNames from "classnames";

const ListItem = ({ text }) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.listImage}>
        <Task />
      </div>
      <div className="card-text">{text}</div>
    </div>
  );
};

const Apply = ({ goToNextStep, goBack }) => {
  const applyNow = () => {
    goToNextStep();
  };

  return (
    <Card
      title={"Ready to apply?"}
      subTitle={"Please make sure you are aware of the following details"}
      goBack={() => {
        goBack();
      }}
    >
      <ListItem
        text={
          "This is a self employed opportunity on the UK Mainland, with gross earnings potential of £50,000 to £60,000 per year"
        }
      />
      <ListItem
        text={
          "We will perform a credit check when you apply to see if we are able to offer you a lease vehicle."
        }
      />
      <ListItem
        text={
          "Once you start providing services a deposit of £1,000 to £2,000 (subject to vehicle type) is required. This is payable in easy instalments and is fully refundable upon the return of your vehicle in good condition and free of damage."
        }
      />
      <ListItem
        text={
          "A member of the Owner Driver team will contact you to arrange for a meeting with the depot. When they do, they will need your driving licence number and DVLA share code information to be provided."
        }
      />
      <ListItem
        text={
          "If you decide to proceed with your application then we will need to validate your Right to Work and your criminal record history through the provision of certain documentation."
        }
      />
      <ListItem
        text={
          "If you choose to use your own vehicle then we will need proof of suitable insurance. We will provide livery for the van at no additional cost (on the first occasion and every 4 years)"
        }
      />
      <button
        className={classNames(
          "btn btn-danger btn-onboarding",
          styles.applyButton
        )}
        onClick={applyNow}
      >
        Apply Now
      </button>
    </Card>
  );
};

export default Apply;
