import React, {useMemo, useEffect} from 'react';
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import Header from 'components/Header';
import Banner from 'components/Banner';
import TopContent from './components/TopContent';
import Strapline from "../../components/Strapline";
import useScript from 'hooks/useScript';
import ChoiceApply from "./components/ChoiceApply";
import "./index.scss";

export const Choice = ({ pageId, windowWidth, urlCampaign }) => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	const localContext = useMemo(
		() => ({
			page: "choice",
			pageId,
			// ... more params in local context
		}),
		[pageId]
	);

	useScript(`https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_PASS}`);

	return (
		<Trackable
			loadId="choicePage"
			interfaceId="chooiceInterface"
			context={localContext}
		>
			<div className='choice'>

				<div className='hero-section'>

					<Header
						active="choice"
						urlCampaign={urlCampaign}
					/>

					<Banner
						subtitle="All you need to know about"
						title="Becoming a Choice Driver"
						banner="banner2"
						windowWidth={windowWidth}
					/>

				</div>

				<TopContent />

				<ChoiceApply />

			</div>
		</Trackable>
	);
}

export default Choice;
