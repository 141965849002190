function isBreakPoint(size) {
  var value = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue("--breakpoint-" + size);
  var matched = window.matchMedia("(min-width: " + value + ")").matches;
  return matched;
}

const isMobile = !isBreakPoint("lg");

export { isMobile };
