import React from "react";
import { Transition } from "react-transition-group";

import "./BasicDetails.scss";

const BasicDetails = ({ children, isOpen }) => {
  const duration = 150;

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateX(100%)",
  };

  const transitionStyles = {
    entering: { transform: "translateX(0%)" },
    entered: { transform: "translateX(0)" },
  };

  // fix fow warning
  // Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode
  const nodeRef = React.useRef(null);

  return (
    <Transition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={duration}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <div
          ref={nodeRef}
          className={`basic-details`}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default BasicDetails;
