import React, { useMemo, useEffect } from "react";
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import Header from "components/Header";
import Banner from "components/Banner";
import Cards from "components/Cards";
import HexagonContent from "components/HexagonContent";
import VideoSection from "components/VideoSection";
import TopContent from "./components/TopContent";
import Vans from "./components/Vans";
import FloatingButton from "components/FloatingButton";
import driver1 from "assets/images/driver1.png";
import driver2 from "assets/images/driver2.png";

export const Index = ({ pageId, windowWidth, urlCampaign }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const localContext = useMemo(
    () => ({
      page: "home",
      pageId,
      // ... more params in local context
    }),
    [pageId]
  );

  return (
    <Trackable
      loadId="homePage"
      interfaceId="homeInterface"
      context={localContext}
    >
      <div className="index">
        <div className="hero-section">
          <Header active="home" urlCampaign={urlCampaign} />

          <Banner
            subtitle="become a DPD DRIVER and earn £50K to £60K revenue"
            title="Choose DPD"
            banner="banner1"
            windowWidth={windowWidth}
          />
        </div>

        <TopContent />

        <Cards urlCampaign={urlCampaign} />

        <Vans windowWidth={windowWidth} />

        <HexagonContent
          imageAlignment="right"
          image={driver1}
          header="Owner Driver"
          list={[
            "From £50K to £60K per route",
            "You are responsible for your own tax and insurance contributions",
          ]}
          anchorId="driver"
          urlCampaign={urlCampaign}
        />

        <HexagonContent
          imageAlignment="left"
          image={driver2}
          header="Owner Driver Worker"
          text="Enjoy the freedom of being self employed, with the security of knowing your holidays will be taken care of."
          list={["From £27K", "Sick Pay", "Paid Holiday", "Pension"]}
          anchorId="worker"
          urlCampaign={urlCampaign}
        />

        <VideoSection urlCampaign={urlCampaign} />

        <FloatingButton urlCampaign={urlCampaign} />
      </div>
    </Trackable>
  );
};

export default Index;
