import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { HOME_CARDS_CONTENT, ANIMATION_DELAY } from "constants";
import * as routes from "../../router/constants";

export const Cards = () => {
  return (
    <section className="cards">
      <div className="container">
        <h1 className="text-secondary text-center">How much can I earn?</h1>

        <div className="content">
          {HOME_CARDS_CONTENT.map(
            (
              {
                subheader,
                header,
                button,
                list,
                anchor,
                externalLink,
                animate,
                newTab
              },
              index
            ) => {
              let animateHelper;

              switch (animate) {
                case "left":
                  animateHelper = "fadeInLeft";
                  break;
                case "right":
                  animateHelper = "fadeInRight";
                  break;
                default:
                  animateHelper = "fadeIn";
              }

              return (
                <ScrollAnimation
                  animateIn={animateHelper}
                  delay={ANIMATION_DELAY.default}
                  initiallyVisible={false}
                  key={header}
                  animateOnce={true}
                >
                  <div className="card">
                    <h6 className="text-primary">{subheader}</h6>
                    <h3 className="text-secondary">{header}</h3>
                    <hr />
                    <ul>
                      {list.map((listItem, index) => {
                        return <li key={index}>{listItem}</li>;
                      })}
                    </ul>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={externalLink ? anchor : `#${anchor}`}
                      target={newTab ? "_blank" : ""}
                      rel={externalLink ? "noreferrer" : ""}
                    >
                      <button className="btn btn-primary">{button}</button>
                    </a>
                  </div>
                </ScrollAnimation>
              );
            }
          )}
        </div>

        <div className="btn-row">
          <a href={routes.APPLY}>
            <button className="btn btn-primary">Contact Us</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Cards;
