import { useContext } from "react";

import dpdLogo from "../../assets/images/dpd-logo.gif";
import styles from "./Loader.module.scss";
import { LoaderContext } from "../../context/Loader";

export default function Loader() {
  const { isLoading } = useContext(LoaderContext);

  return (
    isLoading && (
      <div className={styles.wrapper}>
        <img src={dpdLogo} alt="loader" />
      </div>
    )
  );
}
