import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  isValid,
  redirectURL,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        !!isValid ? (
          Component && <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectURL,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
