import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useField, useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import { map, uniqBy } from "lodash";

import CloseIcon from "../../../../assets/icons/CloseIcon";
import { transformAddressValues } from "../../../../models/address";
import styles from "./CustomField.module.scss";
import { referenceApi } from "../../api";

const filterBy = () => true;
const pageSize = 20;

const Autocomplete = props => {
  const autocompleteRef = useRef(null);
  const promiseRef = useRef(new AbortController());
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({ page: 1 });
  const [options, setOptions] = useState([]);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [field, _, helper] = useField(props);
  const { label, error, touched } = props;

  const abortIfPending = useCallback(() => {
    if (promiseRef?.current && typeof promiseRef.current.abort === "function") {
      promiseRef.current.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promiseRef.current]);

  const searchWithStatusHandler = useCallback(
    params => {
      abortIfPending();

      const controller = new AbortController();
      promiseRef.current = controller;

      return referenceApi
        .findByPostcode(
          { ...params, pageSize: pageSize + 1 },
          promiseRef.current
        )
        .finally(() => {
          promiseRef.current = null;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abortIfPending]
  );

  const onSelectionChange = useCallback(
    async (value, field) => {
      if (!value) {
        return setFieldValue(field);
      } else {
        return referenceApi.getFullAddress(value.addressKey).then(data => {
          let addressObj = data?.data?.addressLocation?.address;
          if (!addressObj) {
            return Promise.resolve();
          }

          const { addressLine1, addressLine2 } =
            transformAddressValues(addressObj);

          const fieldName = field.replace(".postcode", "");
          const newAddress = {
            [`${fieldName}.street`]: addressLine1,
            [`${fieldName}.suburb`]: addressLine2,
            [`${fieldName}.city`]: addressObj.town,
            [`${fieldName}.county`]: addressObj.county,
            [`${fieldName}.postcode`]: addressObj.postcode,
          };

          return Promise.all(
            map(newAddress, (value, key) => {
              setFieldTouched(key);
              return setFieldValue(key, value);
            })
          );
        });
      }
    },
    [setFieldValue]
  );

  useEffect(() => abortIfPending, []);

  useEffect(() => {
    if (!field.value && !autocompleteRef.current?.state.text) {
      autocompleteRef.current.clear();
    } else if (
      !field.value &&
      autocompleteRef.current &&
      autocompleteRef.current.state.text
    ) {
      autocompleteRef.current.clear();
    } else if (autocompleteRef.current) {
      const updateValues =
        autocompleteRef.current && autocompleteRef.current.state.isFocused
          ? { text: field.value, selected: [] }
          : { text: field.value, selected: [], showMenu: false };
      autocompleteRef.current.setState(updateValues);
    }
  }, [field.value]);

  const isClearActionAvailable = useMemo(() => field.value, [field.value]);

  const handleChange = useCallback(
    async selection => {
      if (selection[0]) {
        await onSelectionChange(selection[0], props.name);
        autocompleteRef.current?.blur();
      }
    },
    [autocompleteRef, props.name, onSelectionChange]
  );

  const setUniqOptions = useCallback(
    async options => {
      const unique = uniqBy(options, "fullAddress");
      const diff = options.length - unique.length;

      if (diff) {
        autocompleteRef.current.setState(state => {
          return {
            shownResults: state.shownResults - diff,
          };
        });
      }
      setOptions(unique);
    },
    [autocompleteRef, setOptions]
  );

  const handleBlur = useCallback(() => {
    abortIfPending();

    helper.setTouched(true);
    autocompleteRef.current.setState({
      text: field.value || "",
      selected: [],
      showMenu: false,
    });
  }, [field.value, autocompleteRef, helper, abortIfPending]);

  const onClearClick = useCallback(async () => {
    abortIfPending();
    setOptions([]);

    autocompleteRef.current?.focus();

    await onSelectionChange("", props.name);
    autocompleteRef.current?.blur();
  }, [autocompleteRef, onSelectionChange, props.name, abortIfPending]);

  const handleSearch = useCallback(
    postcode => {
      if (!isLoading) {
        setIsLoading(true);
        setOptions([]);
      }

      setQuery({ postcode, page: 1 });
      searchWithStatusHandler({
        postcode,
        page: 1,
      })
        .then(data => {
          setUniqOptions(data?.data || []);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setUniqOptions]
  );

  const handlePagination = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    searchWithStatusHandler({
      postcode: query.postcode,
      page: query.page + pageSize + 1,
    })
      .then(data => {
        setUniqOptions(options.concat(data?.data || []));
        setQuery({
          postcode: query.postcode,
          page: query.page + pageSize + 1, // It`s offset on gcpLocationService
        });
      })
      .catch(e => {
        setOptions([]);
        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, query, searchWithStatusHandler, setUniqOptions]);

  const handleInputChange = useCallback(
    value => {
      abortIfPending();
      setOptions([]);
      setFieldValue(field.name, value);

      if (value.length > 4) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    },
    [setFieldValue, abortIfPending, field, setIsLoading]
  );

  const CustomInput = useCallback(
    ({ inputRef, referenceElementRef, ...inputProps }) => {
      return (
        <div
          className={`${styles.inputContainer} ${styles.inputContainerIcon}`}
        >
          <div className={styles.inputFieldWrapper}>
            <Form.Control
              {...inputProps}
              id={field.name}
              name={field.name}
              className="form-control"
              maxLength={8}
              ref={node => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />

            <label htmlFor={field.name} id={`${field.name}-label`}>
              <div>{label}</div>
            </label>

            {isClearActionAvailable && (
              <div onClick={onClearClick} className={styles.closeIcon}>
                <CloseIcon />
              </div>
            )}
          </div>

          {error && touched ? (
            <div className={styles.error}>{error}</div>
          ) : null}
        </div>
      );
    },
    [error, touched, isClearActionAvailable]
  );

  return (
    <AsyncTypeahead
      ref={autocompleteRef}
      id={props.name}
      multiple={false}
      filterBy={filterBy}
      onChange={handleChange}
      onSearch={handleSearch}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      minLength={5}
      maxResults={pageSize}
      paginationText="Show more"
      maxHeight={"170px"}
      onPaginate={handlePagination}
      labelKey={"postcode"}
      options={options}
      useCache={false}
      renderInput={CustomInput}
      renderMenuItemChildren={address => address.fullAddress}
    />
  );
};

export default Autocomplete;
