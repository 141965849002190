import React from "react";

const Task = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_40_35235)">
      <path
        d="M22.5 5.18L11.09 16.6L6.85 12.36L8.26 10.95L11.09 13.78L21.09 3.78L22.5 5.18ZM20.29 10.22C20.42 10.79 20.5 11.39 20.5 12C20.5 16.42 16.92 20 12.5 20C8.08 20 4.5 16.42 4.5 12C4.5 7.58 8.08 4 12.5 4C14.08 4 15.54 4.46 16.78 5.25L18.22 3.81C16.6 2.67 14.63 2 12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 10.81 22.28 9.67 21.9 8.61L20.29 10.22Z"
        fill="#8AC149"
      />
    </g>
    <defs>
      <clipPath id="clip0_40_35235">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Task;
