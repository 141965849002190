import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import  { handleRecaptcha } from '../../utils/captcha'
import { contactUsValidationSchema} from "../../validation/contactUsValidationSchema";


const ChoiceForm = (props) => {
	const [success, setSuccess] = useState(false);
	const [sumbmitted, setSumbmitted] = useState(false);
	const [formMessage, setFormMessage] = useState('');

	return (
		<div className="contact-form">
			<Formik
				initialValues={{
					name: '',
					postcode: '',
					email_address: '',
					telephone: '',
					vehicle: '',
					valid_uk_licence: false,
					uk_work_permit: false,
					notes: ''
				}}
				validationSchema={contactUsValidationSchema}
				onSubmit={async (values, { resetForm}) => {
					 const isRecaptchaPass = await handleRecaptcha(
						'CONTACT_US',
						process.env.REACT_APP_CAPTCHA_PASS
					);

					 if (isRecaptchaPass) {
						await axios.post(`${process.env.REACT_APP_API_HOST}/contact/`, values
						).then(response => {
							setSumbmitted(true);
							setFormMessage('Thank you for your interest, our local depot will be in touch with you soon.');
							setSuccess(true);
							resetForm();
						}).catch(function(error) {
							setSumbmitted(true);
							setFormMessage('Form submission failed. Please try to resubmit the form.');
							setSuccess(false);
						});

					 }
				}}
			>
				{({ values, handleChange, errors, touched  }) => (
					<Form className="needs-validation">

						<div className="mb-15">
							<label htmlFor="name" className="form-label">Name <sup>*</sup></label>
							<Field name="name" type="text" className="form-control" id="name" placeholder="Enter name"/>
							{errors.name && touched.name ? (
								<div className="error-text mt-7">{errors.name}</div>
							) : <div className="error-text mt-7"></div>}
						</div>

						<div className="mb-15">
							<label htmlFor="postcode" className="form-label">Postcode <sup>*</sup></label>
							<Field name="postcode" type="text" className="form-control text-uppercase" id="postcode" placeholder="Enter postcode" />
							{errors.postcode && touched.postcode ? (
								<div className="error-text mt-7">{errors.postcode}</div>
							) : <div className="error-text mt-7"></div>}
						</div>

						<div className="mb-15">
							<label htmlFor="email_address" className="form-label">Email address <sup>*</sup></label>
							<Field name="email_address" type="email" className="form-control" id="email_address" placeholder="Enter email address"/>
							{errors.email_address && touched.email_address ? (
								<div className="error-text mt-7">{errors.email_address}</div>
							) : <div className="error-text mt-7"></div>}
						</div>

						<div className="mb-15">
							<label htmlFor="telephone" className="form-label">Telephone <sup>*</sup></label>
							<Field name="telephone" type="text" className="form-control" id="telephone" placeholder="Enter telephone"/>
							{errors.telephone && touched.telephone ? (
								<div className="error-text mt-7">{errors.telephone}</div>
							) : <div className="error-text mt-7"></div>}
						</div>

						<div className="mb-15">
							<label htmlFor="vehicle" className="form-label">What vehicle do you have? <sup>*</sup></label>
							<Field name="vehicle" type="text" className="form-control" id="vehicle" placeholder="Enter Vehicle"/>
							{errors.vehicle && touched.vehicle ? (
								<div className="error-text mt-7">{errors.vehicle}</div>
							) : <div className="error-text mt-7"></div>}
						</div>

						<div className="mb-10">
							<label className="form-label d-block mb-15">Do you have a valid UK driving licence? <sup>*</sup></label>
							<div className="form-check form-check-inline">
								<Field name="valid_uk_licence" className="form-check-input" type="checkbox" id="valid_uk_licence"/>
								<label className="form-check-label" htmlFor="valid_uk_licence">Yes, I have a valid UK driving licence</label>
							</div>
							{errors.valid_uk_licence && touched.valid_uk_licence ? (
								<div className="error-text alignment">{errors.valid_uk_licence}</div>
							) : <div className="error-text alignment"></div>}
						</div>

						<div>
							<label className="form-label d-block mb-15">Do you have the right to work in the UK? <sup>*</sup></label>
							<div className="form-check form-check-inline m-0">
								<Field name="uk_work_permit" className="form-check-input" type="checkbox" id="uk_work_permit"/>
								<label className="form-check-label" htmlFor="uk_work_permit">Yes, I have proof of right to work in the UK</label>
							</div>
							{errors.uk_work_permit && touched.uk_work_permit ? (
								<div className="error-text alignment mt-2 mb-lg-0">{errors.uk_work_permit}</div>
							) : <div className="error-text alignment mt-2 mb-lg-0"></div>}
						</div>

						<div className="mb-15">
							<label htmlFor="notes" className="form-label">Notes (Optional)</label>
							<Field name="notes" as="textarea" className="form-control" id="notes" placeholder="Enter notes"/>
						</div>

						{sumbmitted ? (
							<div className={success ? 'message-block success' : 'message-block error'}>
								{formMessage}
							</div>
						) : <div className="message-block d-none d-lg-block"></div>
						}

						<div className="btn-row">
							<a href="https://www.dpd.co.uk/owner-driver-privacy-notice.jsp" target="_blank" rel="noreferrer">Privacy Policy</a>
							<button type="submit" className="btn btn-primary float-end">Submit</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
export default ChoiceForm;
