import React from "react";

const CloseIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_788_822"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="21"
    >
      <rect width="21" height="21" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_788_822)">
      <path
        d="M5.6 16.625L4.375 15.4L9.275 10.5L4.375 5.6L5.6 4.375L10.5 9.275L15.4 4.375L16.625 5.6L11.725 10.5L16.625 15.4L15.4 16.625L10.5 11.725L5.6 16.625Z"
        fill="black"
      />
    </g>
  </svg>
);

export default CloseIcon;
