import React from "react";

const ErrorSign = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_788_816"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_788_816)">
      <path
        d="M0.833496 17.4998L10.0002 1.6665L19.1668 17.4998H0.833496ZM3.7085 15.8332H16.2918L10.0002 4.99984L3.7085 15.8332ZM10.0002 14.9998C10.2363 14.9998 10.4343 14.9198 10.5943 14.7598C10.7538 14.6004 10.8335 14.4026 10.8335 14.1665C10.8335 13.9304 10.7538 13.7326 10.5943 13.5732C10.4343 13.4132 10.2363 13.3332 10.0002 13.3332C9.76405 13.3332 9.56627 13.4132 9.40683 13.5732C9.24683 13.7326 9.16683 13.9304 9.16683 14.1665C9.16683 14.4026 9.24683 14.6004 9.40683 14.7598C9.56627 14.9198 9.76405 14.9998 10.0002 14.9998ZM9.16683 12.4998H10.8335V8.33317H9.16683V12.4998Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

export default ErrorSign;
