import React from "react";
import { Field } from "formik";

import styles from "./CustomField.module.scss";

const CustomField = ({
  type = "text",
  id,
  label,
  hint,
  error,
  touched,
  disabled,
  maxLength,
  minLength,
  ...rest
}) => {
  return (
    <div className={styles.inputContainer}>
      <Field
        id={id}
        name={id}
        type={type}
        className="form-control"
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        {...rest}
      />

      <label htmlFor={id} id={`${id}-label`}>
        <div>{label}</div>
      </label>

      {hint ? <div className={styles.hint}>{hint}</div> : null}

      {error && touched ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
};

export default CustomField;
