import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import signIn from "../../assets/images/signin2.svg";

import styles from "./OnboardingHeader.module.scss";

const OnboardingHeader = () => {
  return (
    <header className={styles.container}>
      <Link to={"/"}>
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>
      <div className={styles.linkWrapper}>
        <span className={styles.text}>Already registered? </span>
        <a
          href={`${process.env.REACT_APP_PORTAL_HOST}login`}
          className={classNames("btn btn-link", styles.link)}
        >
          <span>Sign in</span>
          <img src={signIn} alt="sign in" className={styles.icon} />
        </a>
      </div>
    </header>
  );
};

export default OnboardingHeader;
