import React from "react";
import { pull } from "lodash";

import Card from "../components/Card";
import MyDetails from "../components/MyDetails";
import { SESSION_STORAGE_KEYS } from "../../../constants";

import "./Acknowledgement.scss";

const Acknowledgement = () => {
  const values = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.DETAILS)
  );
  const salutations = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.SALUTATIONS)
  );
  const referenceNumber = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.REFERENCE_NUMBER
  );
  pull(
    Object.values(SESSION_STORAGE_KEYS),
    SESSION_STORAGE_KEYS.PROSPECT_ID
  ).forEach(key => {
    sessionStorage.removeItem(key);
  });

  return (
    <Card
      title={"Thank you for your interest"}
      subTitle={
        "One of our team will be in touch to arrange for you to meet with your local depot.  They will need your driving licence number and a DVLA share code"
      }
    >
      <div className="row status">
        <div className="col-12 col-sm-auto">
          <div className="title">Application Status:</div>
          <div className="badge">Submitted</div>
        </div>
        <div className="col-12 col-sm-auto">
          <div className="reference">Reference:</div>
          <div className="reference-number">{referenceNumber}</div>
        </div>
      </div>
      <MyDetails
        email={values.email}
        values={values}
        salutations={salutations}
      />
    </Card>
  );
};

export default React.memo(Acknowledgement);
