import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY } from "constants";

export const TopContent = props => {
  return (
    <section className="top-content">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="content-container">
            <h1>DPDgroup is the largest parcel delivery network in Europe.</h1>
            <p className="global">
              By choosing to drive for DPD you'll have the chance to earn £50K to £60K in revenue and be joining an open and
              inclusive culture, where people have the opportunity to flourish
              and shape their own careers.
            </p>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
};

export default TopContent;
