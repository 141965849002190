import axios from "axios";

const { REACT_APP_ONBOARDING_API_HOST, REACT_APP_ODF_API_BASE_PATH } =
  process.env;

const odfApiUrl = `${REACT_APP_ONBOARDING_API_HOST}${REACT_APP_ODF_API_BASE_PATH}/odf`;

export const getProspectState = async ({ emailKeyUuid, prospectId }) => {
  const apiUrl = new URL(`${odfApiUrl}/prospect`);

  if (prospectId) {
    apiUrl.searchParams.set("prospectId", prospectId);
  } else if (emailKeyUuid) {
    apiUrl.searchParams.set("emailKeyUuid", emailKeyUuid);
  }

  const { data } = await axios.get(apiUrl.href);

  return data;
};

export const updateProspect = (prospectId, data) =>
  axios.put(`${odfApiUrl}/prospect/${prospectId}`, data);

export const sendEmail = async email => {
  const { data } = await axios.post(`${odfApiUrl}/prospect`, { email });

  return data;
};
