import React, { useState } from "react";
import { Field } from "formik";

import styles from "./CustomField.module.scss";
import visibility from "../../../../assets/images/visibility.svg";
import visibilityOff from "../../../../assets/images/visibility-off.svg";

const CustomPasswordField = ({ id, label, hint, error, touched, disabled }) => {
  const [type, setType] = useState("password");

  return (
    <div className={`${styles.inputContainer} ${styles.inputContainerIcon}`}>
      <div className={styles.inputFieldWrapper}>
        <Field
          name={id}
          type={type}
          className="form-control"
          id={id}
          disabled={disabled}
        />

        <label htmlFor={id} id={`${id}-label`}>
          <div>{label}</div>
        </label>

        <button
          type="button"
          onClick={() => {
            setType(type === "password" ? "text" : "password");
          }}
        >
          {type === "password" ? (
            <img src={visibility} alt="password hidden" />
          ) : (
            <img src={visibilityOff} alt="password visible" />
          )}
        </button>
      </div>

      {hint ? <div className={styles.hint}>{hint}</div> : null}

      {error && touched ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
};

export default CustomPasswordField;
