import React from "react";

import Card from "../ReadyToApply/components/Card";
import Layout from "../../components/CenteredLayout";
import errorImg from "../../assets/images/error.svg";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className="ready-to-apply">
      <Layout>
        <Card noHeader={true}>
          <div className={styles.contentWrapper}>
            <div className={styles.errorImg}>
              <img src={errorImg} alt="email" />
            </div>

            <div className={styles.title}>The page you’re looking for can’t be found</div>

            <div className={styles.description}>Please try logging in or returning to the Drivers Website</div>

            <a href="https://drivers.dpd.co.uk/">
              <button
                type="button"
                className="btn btn-onboarding btn-danger"
              >
                Go to drivers.dpd.co.uk
              </button>
            </a>
          </div>
        </Card>
      </Layout>
    </div>
  );
};

export default NotFound;
