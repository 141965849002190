import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { MOBILE_BREAKPOINT } from "constants";
import scrollArrow from "assets/images/scroll-arrow.svg";

export const Banner = props => {
  const { title, subtitle, banner, windowWidth } = props;

  const scrollPage = () => {
    let bannerElement = document.getElementById("banner");
    let bannerHeight = bannerElement.offsetHeight;
    let headerHeight = windowWidth < MOBILE_BREAKPOINT ? 0 : 88;
    window.scrollTo(0, bannerHeight + headerHeight);
  };

  return (
    <div className={`banner left ${banner}`} id="banner">
      <div className="container outer-container">
        <div className="content">
          <ScrollAnimation
            animateIn="fadeInLeft"
            initiallyVisible={true}
            animatePreScroll={true}
            animateOnce={true}
          >
            <h5>{subtitle}</h5>
            <h2>{title}</h2>
          </ScrollAnimation>
        </div>

        <img
          src={scrollArrow}
          alt="scroll down"
          width="48"
          className="btn-scroll d-none d-md-block"
          onClick={() => scrollPage()}
        />
      </div>
    </div>
  );
};

export default Banner;
