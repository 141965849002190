import axios from "axios";

const { REACT_APP_ONBOARDING_API_HOST, REACT_APP_ODF_API_BASE_PATH } =
  process.env;

const odfApiUrl = `${REACT_APP_ONBOARDING_API_HOST}${REACT_APP_ODF_API_BASE_PATH}/odf`;

export const createApplicant = async ({
  prospectId,
  password,
  telephoneNumber,
  firstName,
  lastName,
  nationalInsuranceNumber,
  address,
  salutationId,
}) => {
  try {
    const { data } = await axios.post(
      `${odfApiUrl}/applicant/new`,
      {
        prospectId,
        password,
        firstName,
        lastName,
        telephoneNumber,
        nationalInsuranceNumber,
        salutationId: Number(salutationId),
        address,
      },
      {
        headers: {
          dpdclient: "odf/driverportal",
        },
      }
    );

    if (data.error) {
      throw data.error;
    } else {
      return data.data;
    }
  } catch (error) {
    throw error;
  }
};
