import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";

const Card = ({ title, subTitle, children, goBack }) => {
  return (
    <div className={classNames("card", styles.registerCard)}>
      <div className={classNames("card-header", styles.cardHeader)}>
        {goBack && <div className={styles.backButton} onClick={goBack} />}
        <div className={styles.cardTitles}>
          <div className={styles.cardTitle}>{title}</div>
          {subTitle && (
            <div
              className={styles.subTitle}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          )}
        </div>
      </div>
      <div className={classNames("card-body", styles.cardBody)}>{children}</div>
    </div>
  );
};

export default Card;
