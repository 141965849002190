import React from "react";

import styles from "./ToastContent.module.scss";
import ErrorSign from "../../assets/icons/ErrorSign";
import CloseIcon from "../../assets/icons/CloseIcon";

const ToastContent = ({ closeToast, title, content }) => {
  return (
    <>
      <div className={styles.header}>
        <div>
          <ErrorSign />
          <span className={styles.title}>{title}</span>
        </div>

        <button onClick={closeToast} type="button">
          <CloseIcon />
        </button>
      </div>

      <div>{content}</div>
    </>
  );
};

export default ToastContent;
