import axios from "axios";

const { REACT_APP_ONBOARDING_API_HOST, REACT_APP_ODF_API_BASE_PATH } =
  process.env;

const referenceApiUrl = `${REACT_APP_ONBOARDING_API_HOST}${REACT_APP_ODF_API_BASE_PATH}/reference`;

export const getSalutations = async () => {
  try {
    const { data } = await axios.get(`${referenceApiUrl}/salutation`, { noLoader: true });

    if (data.error) {
      throw data.error;
    } else {
      return data.data;
    }
  } catch (error) {
    throw error;
  }
};

export const findByPostcode = async (
  { postcode, page, pageSize },
  controller
) => {
  const apiUrl = new URL(`${referenceApiUrl}/address`);
  apiUrl.searchParams.set("postcode", postcode);
  apiUrl.searchParams.set("searchPage", page);
  apiUrl.searchParams.set("searchPageSize", pageSize);
  const data = await axios.get(apiUrl.href, {
    noLoader: true,
    signal: controller.signal,
  });

  if (data?.error) {
    throw data.error;
  } else {
    return data?.data || [];
  }
};

export const getFullAddress = async addressKey => {
  const data = await axios.get(`${referenceApiUrl}/address/${addressKey}`);

  if (data?.error) {
    throw data.error;
  } else {
    return data?.data || null;
  }
};
