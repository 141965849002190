import _ from "lodash";

export default function sanitize(object) {
  if (_.isString(object)) return _sanitizeString(object);
  if (_.isArray(object)) return _sanitizeArray(object);
  if (_.isPlainObject(object)) return _sanitizeObject(object);
  return object;
}

function _sanitizeString(string) {
  return _.isEmpty(string) ? undefined : string.trim();
}

function _sanitizeArray(array) {
  return _.filter(_.map(array, sanitize), _isProvided);
}

function _sanitizeObject(object) {
  return _.pickBy(_.mapValues(object, sanitize), _isProvided);
}

function _isProvided(value) {
  const typeIsNotSupported =
    !_.isNull(value) &&
    !_.isString(value) &&
    !_.isArray(value) &&
    !_.isPlainObject(value);
  return typeIsNotSupported || !_.isEmpty(value);
}
