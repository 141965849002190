import React from "react";
import { toast } from "react-toastify";

import Card from "../components/Card";
import MyDetails from "../components/MyDetails";
import ToastContent from "../../../components/ToastContent";
import { SESSION_STORAGE_KEYS } from "../../../constants";
import { fromCalendarToApi } from "../../../utils/date";
import { applicantApi } from "../api";

import "./Summary.scss";

const Summary = ({ goToNextStep, goBack, goToPrevStep }) => {
  const values = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.DETAILS)
  );
  const salutations = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.SALUTATIONS)
  );

  const prospectId = sessionStorage.getItem(SESSION_STORAGE_KEYS.PROSPECT_ID);
  const confirm = async () => {
    try {
      const { applicantNumber } = await applicantApi.createApplicant({
        ...values,
        prospectId
      });
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.REFERENCE_NUMBER,
        applicantNumber
      );
      goToNextStep();
    } catch (errors) {
      if (errors[0]) {
        toast.error(
          <ToastContent title="Error" content={errors[0].errorMessage} />
        );
      }
    }
  };

  return (
    <Card
      title={"Summary"}
      subTitle={`Here is a summary of the information you have provided for <b>${values.email}</b>. We will use this email to send any further communications to you.`}
      goBack={() => {
        goBack();
      }}
    >
      <MyDetails
        onEdit={goToPrevStep}
        values={values}
        salutations={salutations}
      />
      <button
        className={"btn btn-danger btn-onboarding confirm-button"}
        onClick={confirm}
      >
        Confirm & Submit
      </button>
    </Card>
  );
};

export default Summary;
