import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY } from "constants";
import * as routes from "../../router/constants";

export const HexagonContent = props => {
  const { header, list, text, image, imageAlignment, anchorId } = props;

  return (
    <section className="hexagon-content" id={anchorId}>
      <div className="container">
        <div
          className={
            imageAlignment === "left"
              ? "row row-container left"
              : "row row-container right"
          }
          key={header}
        >
          {/* left image */}
          {imageAlignment === "left" && (
            <div className="col-lg-5 col-xl-6 text-center image-container">
              <ScrollAnimation
                animateIn="fadeInLeft"
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} width="566px" />
              </ScrollAnimation>
            </div>
          )}

          {/* content */}
          <div className="col-lg-7 col-xl-6">
            <div className="content-container">
              <ScrollAnimation
                animateIn={
                  imageAlignment === "left" ? "fadeInRight" : "fadeInLeft"
                }
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <h1 className="text-secondary text-start">{header}</h1>
                <hr />
                <ul>
                  {list.map((listItem, index) => {
                    return <li key={index}>{listItem}</li>;
                  })}
                </ul>

                <p>{text}</p>

                <div className="btn-row">
                  <Link
                    to={{
                      pathname: routes.APPLY,
                    }}
                  >
                    <button className="btn btn-primary">How to apply</button>
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          {/* right image */}
          {imageAlignment === "right" && (
            <div className="col-lg-5 col-xl-6 text-center text-lg-end image-container">
              <ScrollAnimation
                animateIn="fadeInRight"
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} width="566px" />
              </ScrollAnimation>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HexagonContent;
