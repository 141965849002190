import axios from "axios";
import { toast } from "react-toastify";

import ToastContent from "../components/ToastContent";
import { GENERAL_ERROR_MSG } from "../constants";

const REQ_CANCELED = "ERR_CANCELED";

const interceptors = {
  setupRequestInterceptors: showLoader => {
    axios.interceptors.request.use(function (config) {
      if (!config.noLoader) {
        showLoader();
      }

      config.headers.Authorization = process.env.REACT_APP_AUTH_HEADER;
      return config;
    });
  },
  setupResponseInterceptors: hideLoader => {
    axios.interceptors.response.use(
      response => {
        if (response && !response.config.noLoader) {
          hideLoader();
        }

        return response;
      },
      error => {
        if (!error.config.noLoader) {
          hideLoader();
        }

        if (error.code !== REQ_CANCELED) {
          toast.error(
            <ToastContent
              title="Error"
              content={GENERAL_ERROR_MSG}
            />
          );

          throw error;
        }
      }
    );
  },
};

export default interceptors;
