// @see: https://it.dpduk.live/it/diagram/diag_2dgPzx6C48kIGMvJ.html
export const transformAddressValues = ({ street, locality, property, organisation }) => {
  let addressLine1;
  let addressLine2;
  let preferredAddressLine1 = property || organisation;

  if (preferredAddressLine1) {
    addressLine1 = `${preferredAddressLine1}${street ? ", " + street : ""}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = preferredAddressLine1;
    addressLine2 = `${street}${locality ? ", " + locality : ""}`;

    if (addressLine2 && addressLine2.length > 35) {
      addressLine2 = street;
    }
  } else {
    addressLine2 = locality;
  }

  return {
    addressLine1,
    addressLine2,
  };
};
